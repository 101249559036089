import logo from "./logo1.png";
import "./App.css";
import { useState, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import MLM from "./contract";

function App() {
  const [address, setAddress] = useState("");
  const [addressArray, setAddressArray] = useState([]);
  const [userAddress, setUserAddress] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    return () => {};
  }, []);
 
  const handleWalletConnect = async () => {
    // try {
    //   await provider.enable();
    // } catch (error) {}
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then(handleAccountsChanged)
        .catch((err) => {
          if (err.code === 4001) {
            // EIP-1193 userRejectedRequest error
            // If this happens, the user rejected the connection request.
          } else {
            console.error(err);
          }
        });
      return true;
    } else {
      console.log("Please connect to MetaMask.");
      return false;
    }
  };
  function handleAccountsChanged(accounts) {
    let currentAccount;

    if (window.ethereum) {
      if (window.ethereum.networkVersion !== "137") {
        toast.error("Please connect to MATIC Mainnet");
      }
    }

    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      // console.log("Please connect to MetaMask.");
    } else if (accounts[0] !== currentAccount) {
      currentAccount = accounts[0];
      setUserAddress(currentAccount);

      // Do any other work!
    }
  }
  const removeItem = (val) => {
    let filteredArr = addressArray.filter((el, id) => id !== val);
    setAddressArray(filteredArr);
  };

  const addWhiteList = async (val) => {
    if (!userAddress) {
      return toast.error("Please connect MetaMask first !");
    }
    try {
      console.log("hanlde",val)
      setLoader(true);
      let whitelist = await MLM.sd1212(val);
      let waitForTx = await whitelist.wait();
      if (waitForTx) {
        toast.success("Add whitelist address done !");
        setLoader(false);
        setAddress('');
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong !");
      setLoader(false);
    }
  };
  const handleAddressArray=()=>{
    
    let _address=address.split(',');
    console.log("check",_address)
    addWhiteList(_address)
  }

  return (
    <>
      <ToastContainer />
      <nav className="navbar navbar-expand-lg bg-light">
        <div className="container-fluid">
          <a className="navbar-brand ms-4" href="#">
            <img src="./logo.png" 
            alt="logo" 
            className="logo"
            width={10}
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item mt-2 mt-md-0">
                <button
                  className="btn btn-outline-info"
                  onClick={handleWalletConnect}
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    width: "160px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {userAddress ? userAddress : "Connect Wallet"}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="container-fluid mt-5 pt-5">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-lg-5 ">
            <div className="card px-4 pb-4 pt-4">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                >
                  Enter Address's
                </label>
                <div className="row pe-3">
                  <div className="col">
                    <textarea
                    cols={15}
                    rows={7}
                      type="text"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Enter Address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </div>
               
                </div>
              </div>
              {addressArray.map((e, i) => {
                return (
                  <div className="row mx-1 my-1 " key={i}>
                    <div className="col-10 border rounded">
                      <p className="pt-3 m-0">{e}</p>
                    </div>
                    <div className="col-2 ">
                      <button
                        className="btn px-3 my-2 btn-outline-danger "
                        onClick={() => {
                          removeItem(i);
                        }}
                      >
                        {"-"}
                      </button>
                    </div>
                  </div>
                );
              })}
              <div className="row d-flex justify-content-center mt-3">
                <div className="col-lg-3 d-flex justify-content-center">
                <>
                      {loader ? (
                        <div
                          className="spinner-border text-success"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : (
                        <button
                          className="btn btn-success"
                          onClick={handleAddressArray}
                        >
                          {" "}
                          submit
                        </button>
                      )}
                    </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
